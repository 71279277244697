import { WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';

import CSSModules from 'react-css-modules';
import React from 'react';
import { ShareActionOptions } from 'src/redux/modules/page';
import { buildUrl } from '../../helpers/url';
import currencyMap from '../../helpers/currencyMap';
import { generateHash } from '../../helpers/hashId';
import styles from './TwitterShare.scss';

interface Props extends WithOptimizelyProps {
  shareOnTwitter: (options: ShareActionOptions) => void;
  socialShareUrl: string;
  targetAmount: number;
  pitchFor: string;
  isOwner: boolean;
  isActive: boolean;
  customStyle?: string;
  buttonText?: string;
  className?: string;
  location: string;
  onClickCallback?: () => void;
  targetCurrency: Currency;
}

class TwitterShare extends React.Component<Props> {
  truncate(str: string, length: number) {
    return str.length > length ? `${str.substring(0, length)}..` : str;
  }

  handleTwitterShare = () => {
    const {
      targetAmount,
      pitchFor,
      shareOnTwitter,
      socialShareUrl,
      location,
      isOwner,
      onClickCallback,
      targetCurrency,
      optimizely,
    } = this.props;

    optimizely.track('shareSocialButtonClick');

    if (onClickCallback) {
      onClickCallback();
    }

    const shareHash = generateHash();
    const target = `${currencyMap[targetCurrency] || '£'}${targetAmount}`;

    const text = isOwner
      ? `Help me raise ${target} to ${pitchFor}. Please #donate on @justgiving and RT. Thanks!`
      : `Help raise ${target} to ${this.truncate(
          pitchFor,
          55
        )} Please #donate on @justgiving and RT`;
    const utmId = isOwner ? 60 : 66;

    const url = buildUrl('https://twitter.com/share', {
      text,
      url: buildUrl(socialShareUrl, { utm_id: utmId, utm_term: shareHash }),
    });

    shareOnTwitter({
      shareHash,
      location,
    });

    if (__CLIENT__) {
      window.open(
        url,
        'twitterShareWindow',
        'status=1, height=300, width=640, resizable=1'
      );
    }
  };

  render() {
    const { isActive, customStyle, buttonText, className } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={className}
        styleName={customStyle}
        onClick={this.handleTwitterShare}
      >
        {buttonText || 'Twitter'}
      </button>
    );
  }
}

export default withOptimizely(CSSModules(TwitterShare, styles));
