import React, { Component } from 'react';
import { WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';

import { FB_APP_ID } from 'config';
import PropTypes from 'prop-types';
import { ShareActionOptions } from 'src/redux/modules/page';
import { generateHash } from '../../helpers/hashId';

interface Props extends WithOptimizelyProps {
  socialShareUrl: string;
  isActive: boolean;
  location: string;
  buttonText: string;
  className?: string;
  customStyle?: string;
  shareOnMessenger: (options: ShareActionOptions) => void;
}

class MessengerShare extends Component<Props> {
  static propTypes = {
    shareOnMessenger: PropTypes.func,
    socialShareUrl: PropTypes.string,
    isActive: PropTypes.bool,
    customStyle: PropTypes.string,
    location: PropTypes.string,
    buttonText: PropTypes.string,
    className: PropTypes.string,
  };

  handleMessengerShare = () => {
    const {
      socialShareUrl,
      shareOnMessenger,
      location,
      optimizely,
    } = this.props;
    optimizely.track('shareSocialButtonClick');

    const shareHash = generateHash();

    shareOnMessenger({
      shareHash,
      location,
    });

    if (__CLIENT__) {
      window.location.href = `fb-messenger://share?link=${encodeURIComponent(
        socialShareUrl
      )}&app_id=${FB_APP_ID}`;
    }
  };

  render() {
    const { isActive, customStyle, buttonText, className } = this.props;

    return (
      <button
        type="button"
        disabled={!isActive}
        className={className || ''}
        styleName={customStyle}
        onClick={this.handleMessengerShare}
      >
        {buttonText || 'Messenger'}
      </button>
    );
  }
}

export default withOptimizely(MessengerShare);
