import Clipboard from 'react-clipboard.js';
import React from 'react';
import { ShareActionOptions } from 'src/redux/modules/page';
import Svg from '@justgiving/svg';
import { buildUrl } from 'src/helpers/url';
import { generateHash } from 'src/helpers/hashId';
import linkIcon from '@justgiving/icons/lib/link.svg';
import tickIcon from '@justgiving/icons/lib/tick.svg';

interface Props {
  className?: string;
  copyText: string;
  copiedText: string;
  isDisabled?: boolean;
  pageSection: string;
  socialShareUrl: string;
  sharePageLink: (options: ShareActionOptions) => void;
}

interface State {
  copied: boolean;
  copyLinkUrl: string;
  copyLinkUtmTerm: string;
}

class CopyLink extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { copied: false, ...this.generateCopyLinkState() };
  }

  onCopyUrlToClipboardSuccess = () => {
    const { pageSection, sharePageLink } = this.props;
    const { copyLinkUtmTerm } = this.state;

    sharePageLink({ shareHash: copyLinkUtmTerm, location: pageSection });

    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false, ...this.generateCopyLinkState() });
    }, 5000);
  };

  generateCopyLinkState = () => {
    const { socialShareUrl } = this.props;
    const utmTerm = generateHash();
    const url = buildUrl(socialShareUrl, { utm_term: utmTerm });

    return { copyLinkUrl: url, copyLinkUtmTerm: utmTerm };
  };

  render() {
    const { copyText, copiedText, className, isDisabled } = this.props;

    return (
      <div className={className}>
        <Clipboard
          className="jg-btn jg-btn--ghost"
          button-disabled={isDisabled}
          data-clipboard-text={this.state.copyLinkUrl}
          onSuccess={this.onCopyUrlToClipboardSuccess}
        >
          {!this.state.copied ? (
            <div>
              <Svg
                markup={linkIcon}
                className="jg-icon jg-fill--nobel jg-icon--medium jg-display-flex jg-position-absolute"
              />
              <p className="jg-text--large">{copyText}</p>
            </div>
          ) : (
            <div>
              <Svg
                markup={tickIcon}
                className="jg-icon jg-fill--mountain-meadow jg-icon--medium jg-display-flex jg-position-absolute"
              />
              <p className="jg-text--large">{copiedText}</p>
            </div>
          )}
        </Clipboard>
      </div>
    );
  }
}

export default CopyLink;
