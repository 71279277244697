import { JWTAccessToken } from '@justgiving/auth/es/types/AccessToken';
import JwtDecode from 'jwt-decode';
import { v4 } from 'uuid';
import log from 'src/logging';

type GetUserIdParams = {
  analyticsCookie?: string;
  jgAuthCookie?: string;
};

export function getUserId({ analyticsCookie, jgAuthCookie }: GetUserIdParams) {
  log.info('Fetching session id for optimizely', {
    analyticsCookie,
    jgAuthCookie,
  });

  const analyticsId = getIdFromAnalyticsCookie(analyticsCookie);
  if (analyticsId) return analyticsId;

  const jgUserId = getUserIdFromCookie(jgAuthCookie);
  if (jgUserId) return jgUserId;

  return v4();
}

export function getIdFromAnalyticsCookie(analyticsCookie: string) {
  if (!analyticsCookie) return null;

  return (
    new URLSearchParams(analyticsCookie).get('logged_out_guid') ?? undefined
  );
}

export function getUserIdFromCookie(authCookie: string) {
  if (!authCookie) return null;

  const jwt = new URLSearchParams(authCookie).get('access_token');
  if (!jwt) return null;

  const decodedJwt = JwtDecode<JWTAccessToken>(jwt);
  return decodedJwt.sub;
}
