import { WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';

import CSSModules from 'react-css-modules';
import Clipboard from 'react-clipboard.js';
import React from 'react';
import { ShareActionOptions } from 'src/redux/modules/page';
import Svg from '@justgiving/svg';
import { buildUrl } from 'src/helpers/url';
import { generateHash } from 'src/helpers/hashId';
import linkIcon from '@justgiving/icons/lib/link.svg';
import styles from './CopyLinkShareButton.scss';

interface Props extends WithOptimizelyProps {
  copyText: string;
  copiedText: string;
  isDisabled?: boolean;
  pageSection: string;
  socialShareUrl: string;
  sharePageLink: (options: ShareActionOptions) => void;
}

interface State {
  copied: boolean;
  copyLinkUrl: string;
  copyLinkUtmTerm: string;
}

class CopyLinkShareButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { copied: false, ...this.generateCopyLinkState() };
  }

  onCopyUrlToClipboardSuccess = () => {
    const { pageSection, sharePageLink, optimizely } = this.props;
    const { copyLinkUtmTerm } = this.state;

    optimizely.track('share_copy_link_click');

    sharePageLink({ shareHash: copyLinkUtmTerm, location: pageSection });

    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({ copied: false, ...this.generateCopyLinkState() });
    }, 5000);
  };

  generateCopyLinkState = () => {
    const { socialShareUrl } = this.props;
    const utmTerm = generateHash();
    const url = buildUrl(socialShareUrl, { utm_term: utmTerm });

    return { copyLinkUrl: url, copyLinkUtmTerm: utmTerm };
  };

  render() {
    const { copyText, copiedText, isDisabled } = this.props;
    const buttonText = !this.state.copied ? copyText : copiedText;

    return (
      <div className={styles.copy}>
        <Svg
          markup={linkIcon}
          className="jg-icon jg-fill--nobel jg-position-absolute"
        />
        <div className={styles.url}>{this.state.copyLinkUrl}</div>
        <Clipboard
          button-disabled={isDisabled}
          data-clipboard-text={this.state.copyLinkUrl}
          onSuccess={this.onCopyUrlToClipboardSuccess}
        >
          {buttonText}
        </Clipboard>
      </div>
    );
  }
}

export default withOptimizely(CSSModules(CopyLinkShareButton, styles));
