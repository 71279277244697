import {
  PageState,
  shareOnFacebook as shareOnFacebookAction,
  shareOnFacebookCancelled as shareOnFacebookCancelledAction,
  shareOnFacebookOpened as shareOnFacebookOpenedAction,
} from 'src/redux/modules/page';
import { WithOptimizelyProps, withOptimizely } from '@optimizely/react-sdk';
import {
  isActive as isPageActive,
  isClosed as isPageClosed,
} from 'src/helpers/page';

import { AppState } from 'src/redux/modules';
import React from 'react';
import { bindActionCreators } from 'redux';
import { buildUrl } from 'src/helpers/url';
import { connect } from 'react-redux';
import currencyMap from 'src/helpers/currencyMap';
import { generateHash } from 'src/helpers/hashId';
import getMediaSource from 'src/helpers/media';
import { share } from 'src/helpers/facebook';

interface OwnProps {
  shareOnFacebookOpened?: () => void;
  beforeSharingOnFacebook?: () => void;
  isUpdate?: boolean;
  buttonText: string;
  location: string;
  shareHash?: string;
  eventValue?: string;
  customStyle?: string;
  media?: {
    type: number;
    path: string;
  }[];
  message?: string;
  quote?: string;
  updateId?: number;
  showCounter?: boolean;
  mobileIframe?: boolean;
  renderButtonOnly?: boolean;
  className?: string;
  isActive?: boolean;
}

interface StateProps {
  page: PageState;
  isActive: boolean;
  isClosed: boolean;
  raised?: number;
}

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
  page: state.page,
  isActive: ownProps.isActive || isPageActive(state.page),
  isClosed: isPageClosed(state.page),
  raised:
    (isPageClosed(state.page)
      ? state.page.amountRaised
      : state.page.amountPledged) || 0,
});

interface DispatchProps {
  shareOnFacebook: (data: any) => void;
  shareOnFacebookOpened: (data: any) => void;
  shareOnFacebookCancelled: (data: any) => void;
}

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps =>
  bindActionCreators(
    {
      shareOnFacebook: shareOnFacebookAction,
      shareOnFacebookOpened: data => {
        if (ownProps.shareOnFacebookOpened) {
          ownProps.shareOnFacebookOpened();
        }

        return shareOnFacebookOpenedAction(data);
      },
      shareOnFacebookCancelled: shareOnFacebookCancelledAction,
    },
    dispatch
  );

type Props = StateProps & DispatchProps & OwnProps & WithOptimizelyProps;

class FacebookShare extends React.Component<Props> {
  static propTypes = {};

  static defaultProps = {
    renderButtonOnly: false,
  };

  getFacebookShareVariation() {
    const {
      page: { targetAmount, pitchFor, ownerName, targetCurrency },
    } = this.props;

    const variationId = Math.floor(Math.random() * 3 + 106);

    switch (variationId) {
      case 106:
        return {
          id: variationId,
          title: `Help raise ${currencyMap[targetCurrency] ||
            '£'}${targetAmount} to ${pitchFor}`,
          reference: 'CFPage_share_B',
        };
      case 107:
        return {
          id: variationId,
          title: `${ownerName} needs your help`,
          reference: 'CFPage_share_C',
        };
      case 108:
        return {
          id: variationId,
          title: `Read ${ownerName}'s amazing story`,
          reference: 'CFPage_share_D',
        };
      default:
        throw new Error(`Unsupported share variation ${variationId}`);
    }
  }

  getUpdateShareVariation() {
    const {
      page: { targetAmount, pitchFor, targetCurrency },
      message,
      isClosed,
      raised,
    } = this.props;

    if (isClosed) {
      return {
        name: `I've raised ${currencyMap[targetCurrency] ||
          '£'}${raised} on JustGiving to ${pitchFor}`,
        description: message || 'Thank you for your support',
      };
    }

    return {
      name: `Help raise ${currencyMap[targetCurrency] ||
        '£'}${targetAmount} to ${pitchFor}`,
      description: message,
    };
  }

  constructUrl(path: string) {
    const { updateId } = this.props;
    return path + updateId;
  }

  handleFacebookShare = () => {
    const {
      page: { isOwnerRequested, facebookShareImage },
      isUpdate,
      shareOnFacebook,
      shareOnFacebookOpened,
      shareOnFacebookCancelled,
      location,
      shareHash,
      media,
      quote,
      beforeSharingOnFacebook,
      eventValue,
      optimizely,
    } = this.props;

    optimizely.track('shareSocialButtonClick');

    const hash = shareHash || generateHash();

    let sharePromise;

    if (isUpdate) {
      const shareVariation = this.getUpdateShareVariation();

      sharePromise = this.shareUpdateOnFacebookFeed({
        name: shareVariation.name,
        picture:
          media && media.length > 0
            ? getMediaSource(media[0])
            : facebookShareImage,
        description: shareVariation.description,
        shareHash: hash,
        quote,
      });
    } else if (isOwnerRequested) {
      sharePromise = this.sharePageOnFacebookFeed({
        name: 'Donate to my Crowdfunding Page',
        campaign: 'projectpage-share-owner',
        shareHash: hash,
      });
    } else {
      const shareVariation = this.getFacebookShareVariation();

      sharePromise = this.supporterSharePageOnFacebookFeed({
        id: shareVariation.id,
        name: shareVariation.title,
        reference: shareVariation.reference,
        shareHash: hash,
      });
    }

    if (beforeSharingOnFacebook) {
      beforeSharingOnFacebook();
    }

    shareOnFacebook({
      shareHash: hash,
      location,
      eventValue,
    });

    sharePromise.then(
      () => {
        shareOnFacebookOpened({
          shareHash: hash,
          location,
          eventValue,
        });
      },
      (error: string) => {
        shareOnFacebookCancelled({ error });
      }
    );
  };

  shareUpdateOnFacebookFeed(
    options: {
      id?: string;
      shareHash?: string;
      picture?: string | null;
      name?: string;
      quote?: string;
      description?: string;
    } = {}
  ) {
    const {
      page: { socialShareUrl },
      updateId,
    } = this.props;

    const url = updateId
      ? `${socialShareUrl}/updates/${updateId.toString(16)}`
      : socialShareUrl;

    const shareUrl = buildUrl(url, {
      utm_id: options.id,
      utm_term: options.shareHash,
    });

    return share({
      method: 'share',
      ref: 'projectpage',
      link: shareUrl,
      picture: options.picture,
      name: options.name,
      description: options.description,
      quote: options.quote,
    });
  }

  sharePageOnFacebookFeed(
    options: {
      campaign?: string;
      shareHash?: string;
      name?: string;
      quote?: string;
      description?: string;
    } = {}
  ) {
    const {
      page: {
        socialShareUrl,
        name,
        targetAmount,
        pitchFor,
        pitchBecause,
        facebookShareImage,
        targetCurrency,
      },
    } = this.props;

    const shareUrl = buildUrl(socialShareUrl, {
      utm_source: 'Facebook',
      utm_medium: 'Yimbyprojectpage',
      utm_content: name,
      utm_campaign: options.campaign,
      utm_term: options.shareHash,
    });

    return share({
      method: 'feed',
      link: shareUrl,
      ref: 'projectpage',
      picture: facebookShareImage,
      name: options.name,
      description:
        options.description ||
        `I'm raising ${currencyMap[targetCurrency] ||
          '£'}${targetAmount} to ${pitchFor} ${
          pitchBecause ? ` because ${pitchBecause}` : ''
        }`,
      quote: options.quote,
    });
  }

  supporterSharePageOnFacebookFeed(
    options: {
      id?: number;
      shareHash?: string;
      reference?: string;
      name?: string;
    } = {}
  ) {
    const {
      page: { socialShareUrl, ownerName, facebookShareImage },
      mobileIframe,
    } = this.props;

    const shareUrl = buildUrl(socialShareUrl, {
      utm_id: options.id,
      utm_term: options.shareHash,
    });

    return share({
      method: 'share',
      link: shareUrl,
      ref: options.reference,
      picture: facebookShareImage,
      name: options.name,
      description: `${ownerName} is raising money on JustGiving Crowdfunding`,
      mobileIframe,
    });
  }

  renderButton() {
    const { isActive, buttonText, customStyle, className = '' } = this.props;

    return (
      <button
        onClick={this.handleFacebookShare}
        type="button"
        disabled={!isActive}
        className={customStyle || `jg-btn jg-btn--facebook ${className}`}
      >
        {buttonText}
      </button>
    );
  }

  render() {
    const { renderButtonOnly } = this.props;

    if (renderButtonOnly) {
      return this.renderButton();
    }

    return <div className="jg-relative">{this.renderButton()}</div>;
  }
}

export default withOptimizely(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(FacebookShare)
);
