import CSSModules from 'react-css-modules';
import React from 'react';
import styles from './ShareButton.scss';
import { useTrackEvent } from '@optimizely/react-sdk';

export interface Props {
  handleShareClick: () => void;
}

const ShareButton: React.StatelessComponent<Props> = ({ handleShareClick }) => {
  const [track] = useTrackEvent();
  const handleShareButtonClick = () => {
    track('share_button_click');
    handleShareClick();
  };

  return (
    <button
      type="button"
      className="jg-btn"
      styleName="share-button"
      onClick={() => handleShareButtonClick()}
    >
      Share
    </button>
  );
};

export default CSSModules(ShareButton, styles, { allowMultiple: true });
