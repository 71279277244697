import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';

import Cookies from 'cookies-js';
import React from 'react';
import { clientAuth } from 'src/helpers/auth';
import config from 'config';
import { getUserId } from '../helpers/optimizely';

export const optimizelyInstance = createInstance({
  sdkKey: config.optimizelySdkKey,
});

export const CustomOptimizelyProvider = ({ children }) => {
  return (
    <OptimizelyProvider
      optimizely={optimizelyInstance}
      user={{
        id: getUserId({
          jgAuthCookie: clientAuth.getAuthCookie(),
          analyticsCookie: Cookies.get(config.JG_ANALYTICS_COOKIE_NAME),
        }),
      }}
    >
      {children}
    </OptimizelyProvider>
  );
};
